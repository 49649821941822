@import "../../../assets/styles/variables";


.accordion__main {
    .MuiOutlinedInput-input {
        padding-top: 12px !important;
        padding-bottom: 11px !important;
    }
    .MuiPaper-root {
        &.MuiPaper-elevation {
            border: 0;
            border-bottom: 1px solid #F3F3F3;
            border-radius: 0;

            .MuiButtonBase-root {
                display: flex;
                padding: 0;
            }
        }
        &:before {
            height: 0;
        }
    }
    .MuiAccordionSummary-root {
        svg {
            fill: #707070;
        }
    }
    .MuiAccordionSummary-content {
        margin-top: 1px;
        margin-bottom: 1px;
        .accordion-label {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.05em;
            color: #707070;
        }
        &.Mui-expanded {
            p.accordion-label{
                color: $tenant_color_primario !important;
                //text-decoration: underline;
            }
        }
    }

    .MuiCollapse-root {
        border-bottom: 1px solid rgba(0, 170, 128, 0.1);

        .MuiAccordionDetails-root {
            padding: 0;
        }
        .MuiFormControl-root {
            width: 100%;
            .MuiFormControlLabel-root {
                align-content: center;
                align-items: center;
                display: flex;
                flex-direction: row-reverse;
                flex-wrap: nowrap;
                height: 50px;
                justify-content: space-between !important;
                margin: 0;
            }
            .MuiTypography-root {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.05em;
                color: $tenant_negro;

            }
            .MuiSvgIcon-root {
                fill: $tenant_color_primario;
            }
        }
    }
    &--plus {
        .MuiPaper-root {
            color: rgba(0, 0, 0, 0.87);
            transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            background-color: #fff;
            &.MuiPaper-elevation {
                margin-bottom: 10px;
            }
        }
        .MuiAccordionSummary-root {
            border-bottom: 2px solid #E0E0E0;
            svg {
                fill: #000000;
            }
        }
    }
    &--no-border {
        .MuiCollapse-root {
            border-bottom: 0;
        }
    }
}

.MuiButtonBase-root.Mui-expanded {
    min-height: 50px !important;
}

