@import "../../assets/styles/variables";

.modal_tenant_alert_variant_message {
  min-height: 10% !important;
  width: 400px !important;
  min-width: 0 !important;
  border-radius: 20px;
}

@media (max-width: 768px) {
  .modal_tenant_alert_variant_message {
    min-width: 83.33% !important;
  }
}

.modal_tenant_alert_variant_message_title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.modal_tenant_alert_variant_message_title__text {
  font-family: 'Nunito Sans', 'Helvetica Neue', 'Inter', Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 28px;
  font-size: 18px;
  color: #707070;
}

.modal_tenant_alert_variant_message_text {
  margin-top: 20px;
  margin-bottom: 30px;
  font-family: 'Nunito Sans', 'Helvetica Neue', 'Inter', Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: #707070;
}

.modal_tenant_alert_variant_message_svg {
  fill: $tenant_deficiente !important;
}

.modal_tenant_alert_variant_button_container {
  display: flex;
  gap: 20px;
}

.modal_tenant_alert_variant_button_container__variant{
  background-color: white !important;
  color: #707070 !important;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  border: 1px solid #dcd9d9;
}

.modal_tenant_alert_variant_button_container__variant:hover {
  background-color: $tenant_color_primario;
  color: $tenant_gris_1;
  border: 1px solid $tenant_color_primario;
  opacity: 0.85;
}
