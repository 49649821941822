@import "../../assets/styles/variables";

.loading_spin__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    position: fixed;
    z-index: 9999;
    top:0px;
    left: 0px;
    background-color: $tenant_blanco;
    width: 100vw;
  
    span {
      width: 60px !important;
      height: 60px !important;
    }
  
    svg {
      color: var(--tenant_color_primario) !important;
    }
  }
  
  .loading_spin_logo {
    position: absolute;
    top: 60px;
    text-align: center;
    p {
      color: $tenant_gris_1;
    }
  }
  
  .stop-scrolling {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  .loader-local__main {
    @apply fixed;
    .MuiCircularProgress-root {
        border-color:white ;
    }

}