//fonts
$font-lato: 'lato', Helvetica, Arial;
$font-roboto: 'roboto', Helvetica, Arial;

// font weights
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 600;

$weight-megabold: 700;
$weight-extrabold: 600;
$weight-bold: 500;
$weight-medium: 400;
$weight-light: 300;
$weight-text-bold: 600;

//BREAKPOINTS
$sm: 320px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

//Tenant variables public
$aquamarine:#25B6BF;
$yellow: #FFFF00;
$hyperlink: #007A8C;
$light-gray: #E0E0E0;
$light-blue: #00A0DF;
$light-white: #FAFAFA;
$gray: #4F4F4F;
$dark-gray: #929292;

$tenant_blanco: var(--tenant_blanco);
$tenant_blanco_2: var(--tenant_blanco_2);
$tenant_deficiente: var(--tenant_deficiente);
$tenant_gris_1: var(--tenant_gris_1);
$tenant_gris_2: var(--tenant_gris_2);
$tenant_gris_3: var(--tenant_gris_3);
$tenant_gris_4: var(--tenant_gris_4);
$tenant_negro: var(--tenant_negro);
$tenant_regular: var(--tenant_regular);
$tenant_regular_10_por_ciento: var(--tenant_regular_10_por_ciento);
$tenant_rojo: var(--tenant_rojo);
$tenant_rojo_10_por_ciento: var(--tenant_rojo_10_por_ciento);
$tenant_verde: var(--tenant_verde);
$tenant_verde_10_por_ciento: var(--tenant_verde_10_por_ciento);

//Tenant variables private

$tenant_acento: var(--tenant_acento);
$tenant_acento_10_por_ciento: var(--tenant_acento_10_por_ciento);
$tenant_color_primario: var(--tenant_color_primario);
$tenant_color_secundario: var(--tenant_color_secundario);
$tenant_primario_10_porciento: var(--tenant_primario_10_porciento);
$tenant_secundario_10_por_ciento: var(--tenant_secundario_10_por_ciento);
$tenant_secundario_20_por_ciento: var(--tenant_secundario_20_por_ciento);
$tenant_font_family: 'Inter', 'Helvetica', 'Arial', 'sans-serif';
