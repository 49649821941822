@import "../../assets/styles/variables";

.autocomplete__main {
  background-color: white;

  &.MuiAutocomplete-root fieldset:hover{
    border-color: black;
  }
}
.textfield__label {
  color: $aquamarine;
  font-size: 14px;
  font-weight: $weight-bold;
  line-height: 17px;
  margin-bottom: 2px;
  text-align: start;
}

.autocomplete__main {

  .MuiIconButton-label {
    color: black;
  }
}

.textfield__main {
  width: 100%;
  .MuiOutlinedInput-root {
    border-radius: 20px !important;
  }
  input { 
    padding: 3.5px 5px !important;
  }
}