@import '../../assets/styles/responsive';
@import '../../assets/styles/variables';

.signIn {
  background-image: url('../../assets/images/background_login.svg');
  background-attachment: fixed;
  background-color: #00AA80;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  min-width: 100%;

  @include desktop {
    background-image: url('../../assets/images/backgroud_login_desktop.svg');
  }

  &__container {
    min-height: 100vh;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    min-width: 100%;
  }

  &__container__signIn {
    padding: 20px;
    text-align: center;
    min-width: 100%;
    @include desktop {
      width: 70%;
      min-width: 50%;
      padding-left: 33%;
    }
  }

  &__button {
    width: 100% !important;
    margin-top: 24px !important;
    @include desktop {
      width: 30% !important;
      margin-left: 35% !important;
    }

    &__label {
      color: white !important;
    }

  }

  &__href {
    color: #FFFFFF !important;
    text-decoration: none !important;
  }

  &__division__line {
    background: white;
    border: 0.5px solid white;
    filter: blur(0.5px);
    width: 145px;
    margin: auto;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  &__panel_img {
    min-width: 256px;
    max-width: 256px;
    min-height: 12vh !important;
    margin: 0px !important;
    display: flex;
  }
  &__panel_datos{
    background: white;
    min-height: 12vh !important;
    margin: 0px !important;
    float: center;
  }
}
.general_modal_main{
  .rsm-p-8{
    padding: 0px !important;
  }
  border-radius: 30px !important;
  .general_model_top_content__turn_detail_modal{
    padding: 25px 28px 22px 18px;
    display: flex;
    position: relative;
    width: 100%;
  }
  .general_model_conteiner{
    width: 402px;
    height: 80px;
    margin-top: 20px;
    margin-left: 30px;
    >p{
      text-align: end !important;
      >span{
        text-decoration: underline;
        cursor: pointer;
        color: #46389F;
      }
    }
  }
  .general_model_form{
    width: 328px;
    margin-top: 20px;
    margin-left: 30px;
  }
  .general_model_top_title{
    color: #707070;
    font-family: $tenant_font_family;
    font-weight: 600;
    line-height: 24px;
    font-size: 20px;
  }
  .general_model_top_description{
    margin-top: 40px;
    color: #707070;
    font-family: $tenant_font_family;
    font-weight: 400;
    line-height: 16px;
    font-size: 16px;
  }
  .general_model_form{
    margin-top: 30px;
    color: #707070;
    font-family: $tenant_font_family;
    font-weight: 400;
    line-height: 24px;
    font-size: 15px;
  }
  .general_modal_close {
    cursor: pointer;
    padding: 5px;
    background-color: #fff;
    color: #929292;
    font-size: 25px;
    position: absolute;
    top: 15px;
    right: 23px;
  }
}
.auth__main__content__button {
  margin-top: 25px;
}
.filter-footer {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
    height: 50px;
    justify-content: flex-end;
    margin-top: 30px;
  }
