@import "../../assets/styles/variables";

.modal_tenant_alert_message {
  min-height: 10% !important;
  width: 400px !important;
  min-width: 0 !important;
  border-radius: 20px;
}

@media (max-width: 768px) {
  .modal_tenant_alert_message {
    min-width: 83.33% !important;
  }
}

.modal_tenant_alert_message_title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.modal_tenant_alert_message_title__text {
  font-family: $tenant_font_family;
  font-weight: 500;
  line-height: 28px;
  font-size: 18px;
  color: #707070;
}

.modal_tenant_alert_message_text {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: $tenant_font_family;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: #707070;
}

.modal_tenant_alert_message_svg {
  fill: $tenant_color_primario !important;
}
