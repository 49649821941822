@import "../../assets/styles/variables";

.btn {
  /*background: linear-gradient(179.94deg, $main 0.05%, $main 113.22%);*/
  background: linear-gradient(180deg, #FF3F3F 0%, #C21000 100%);
  border-radius: 10px;
  cursor: pointer;
  width: 100%;

  &.transparent-btn {
    background: #707070;
    /* Boton */

    border: 2px solid #707070;
    box-sizing: border-box;
    border-radius: 30px;
  }
  &.cancel-btn{
    border: 1px solid #707070;
    
    background: #FFFFFF;
    span {
      color: #707070 !important;
      font-style: normal;
      font-weight: $weight-bold;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.MuiButtonBase-root {
    margin-bottom: 13px;
    text-transform: capitalize;
    border-radius: 30px;
    height: 40px;

    &.btn--from-modal {
      border-radius: 0;
    }

    &.MuiButton-contained {
      transition: none;
      &:hover {
        background-color: #707070;
      }
    }
  }

  span {
    color: $light-white;
    font-style: normal;
    font-weight: $weight-bold;
    font-size: 16px;
    line-height: 20px;
  }

  &--blue {
    background: linear-gradient(to right, $light-blue, $light-blue);
  }

  a {
    text-decoration: none;
  }

  &--small {
    span {
      font-size: 12px;
    }
    
    .MuiButton-label {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &--disabled {
    cursor: default;
    background: $light-gray;
    border-radius: 30px;

    .MuiButton-label {
      color: $tenant_blanco;
    }
  }
}

.btn--disabled{
  background-color: #BDBDBD !important;
  cursor: not-allowed !important;
}