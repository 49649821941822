@import '../../assets/styles/responsive';
@import "../../assets/styles/variables";

.table-activations{
 min-height: calc(55vh - 80px);
 overflow-y: scroll;
 max-height: calc(55vh - 80px) !important;
}
.button-finalizar{
padding-left: 15px !important ;
font-weight: 600;
}
.form-activaction{
    padding-top: 70px;
}

.select-form__activation{
    margin: 0px !important;
}

.form__request__history > .MuiContainer-root{
  width: 500px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.form__request__history{
    margin-left: -20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.InputDate {
    position: relative;
    .react-datepicker-wrapper{
        width: 50% !important;
    }
    input {
        font-size: 14px;
        color: black;
    }
    img {
        position: absolute;
        right: 20px;
    }
}
.head_main{
    float: right;
    display: inline;
}
.head_table{
    float:left;
    width: 170px;
}
.footer_main{
    float: right;
    display: inline;
}
.footer_table{
    float:left;
    width: 125px;
}
.vertical__delete__button {
    float: right;
    margin-bottom: 0 !important;
    background: linear-gradient(180deg, #FFFF 0%, #FFFF 100%) !important;
    border-color: #B3B3B3 !important;
    margin-bottom: 20px !important;
    span{
        color: #707070 !important;
    }
}
.contenedorBody{
    z-index:40;
    opacity:.5;
}
.EndSide{

    @include desktop{
      display: flex;
      float:left;
    }
    @include tablet-large {
      display: flex;
      float:left;
    }
}

.esidebar::-webkit-scrollbar-track {
    background-color: transparent;
    display: flex;

}

.esidebar {
  position: fixed !important;
  display: flex;
  border-left:2px solid #46389F;
  top: 0;//72px
  left: calc(100% - 512px);
  width: 512px;
  height: 100vh;
  background-color: #FFF;
  padding: 20px;
  z-index: 2;
  //transition: transform 0.3s ease-in-out;
  box-shadow: 2px 4px 5px -3px rgba(0,0,0,0.30);
  overflow: hidden;
  scrollbar-color: transparent transparent;
  &.is-open {
    transform: translateX(-512px);
  }
}

.esidebar > div > svg {
  cursor: pointer;
}

.request_activation_drawer_animation {
  transition: width 0.5s;
}

.lateralBar{
    transform: scale(1);
    opacity: 1;
    height: 100vh;
}
.general_model_top_content__turn_detail_modal{
    padding: 25px 28px 22px 18px;
    display: flex;
    float:left;
    position: fixed;
    width: 100%;
    height: 10px;
}
.general_container_end{
    padding: 80px 65px 22px 45px;

    display: flex;
    width: 100%;;
}
.div_containe{
    .money{
        float: right;
        padding-left: 150px;
    }
}

.request_activation_adding_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0;
}

.request_activation_adding_text {
  margin-top: 30px;
  cursor: pointer;
}

.request_activation_adding_text:hover {
  opacity: 0.6;
  text-decoration: underline;
}

.request_activation_adding_text:active {
  opacity: 1;
}

.request_activation_container_terms_checkbox > .checkbox__container{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.request_activation_container_terms_checkbox {
  margin-top: 30px;
}

#activation_terms_conditions {
  margin-right: 0 !important;
  text-align: justify;
}

.activaton_drawer_content__button {
  margin-top: 30px;
  width: 100%;
  padding-left: 0;
}

.activation_drawer_table {
  width: 100%;
  border-collapse: collapse;
}

.activation_drawer_table th, td {
  //border: 1px solid black;
  padding: 8px;
  text-align: left;
  font-family: $tenant_font_family;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: #707070;
}

.activation_drawer_td_money {
  text-align: right;
}

.terms_conditions__text {
  font-family: $tenant_font_family;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: #707070;
}

.form__request__history .MuiInputBase-root.MuiOutlinedInput-root {
  height: 44px !important;
}

.rsm-z-40 {
  z-index: 40;
}
.rsm-fixed {
  position: fixed;
}
.rsm-opacity-50 {
  opacity: .5;
}
.rsm-inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.rsm-bg-gray-700 {
  background-color: #4a5568;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl{
  padding: 0 9px;
}
