@import "../../assets/styles/variables";

.MuiPaper-root {
    &.MuiPaper-elevation1 {
        box-shadow: inherit;
        border: 1px solid rgba(224, 224, 224, 1);
    }
}

.header__options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .searchbar__main {
        background: #FEFEFE;
        box-shadow: 0px 1px 4px rgba(54, 63, 72, 0.1);
        border-radius: 8px;
        .searchbar__input {
            min-width: 400px;
            &.MuiPaper-root {
                &.MuiPaper-rounded {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
            .MuiInputBase-root {
                padding-right: 0;
            }
            .searchbar__icon__main {
                width: 48px;
                height: 48px;
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                background: linear-gradient(219.93deg, #00AA80 12.52%, #25B6BF 136.91%);
                border-radius: 8px;

                svg {
                    fill: $tenant_blanco;
                }
            }
        }
    }
    .first__options {
        display: flex;
        gap: 80px;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
    }
    .second__options {
        @apply flex gap-5 relative;

        .filter-button {
            @apply w-12;
            height: 3rem;

            &.icon-button {
                border-radius: 50%;
            }

            &--gray {
                background-color: #EAEAEA;

                svg {
                    path {
                        fill: #737779;
                    }
                }
            }
        }

        .filter-menu {
            --tw-bg-opacity: 1;
            background-color: white;
            border-radius: 20px;
            display: none;
            padding: 24px 24px !important;

            .label-filter-menu-item {
                align-items: center;
                background-color: white !important;
                border-bottom: 1px solid $tenant_color_primario;
                display: flex;
                height: 42px;
                justify-content: space-between;
                padding: 7px 0;

                & > p {
                    font-family: $tenant_font_family;
                    font-weight: 600;
                    color: #707070;
                }

                & > svg {
                    fill: #707070;
                    cursor: pointer;
                }

                & > svg:hover {
                    opacity: 0.6;
                }

                & > svg:active {
                    opacity: 1;
                }
            }

            .filter-menu-item {
                padding: 0 0;

                .accordion-label {
                    color: #707070;
                    font-family: $tenant_font_family;
                    font-weight: 500;
                }

                .textfield__label {
                    color: $tenant_negro;
                }
                .MuiFormControl-root {
                    width: 100%;
                    .multiple-select {
                        .MuiSelect-select {
                            padding: 0.5938rem 2rem 0.5938rem 0.875rem;
                        }
                    }
                }
                .search__main {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    align-content: center;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    gap: 5px;
                    padding: 6px 10px;
                    margin-bottom: 5px;
                    background-color: #FBFBFB;

                    .MuiInputBase-root {
                        &:before,
                        &:after,
                        &:hover {
                            border: none
                        }
                    }
                }
                .accordion__main {
                    &.MuiPaper-root{
                        &.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
                            box-shadow:none !important;
                        }
                    }
                    .dates__main {
                        align-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: row;
                        gap: 0.75rem;
                        justify-content: center;
                        margin-bottom: 0.5rem;
                        padding-top: 0.5rem;
                        border: 0;
                        border-top: 1px solid #F3F3F3;
                        border-radius: 0;

                        .dates__col {
                            .date-picker__main {
                                .text-field {
                                    .MuiOutlinedInput-root {
                                        background: #FFFFFF;
                                        padding-right: 8px;
                                        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px rgba(134, 143, 160, 0.16);
                                        border-radius: 6px;
                                        .MuiOutlinedInput-input {
                                            padding: 7.5px 4px 7.5px 5px;
                                        }
                                    }
                                    .MuiInputAdornment-root .MuiButtonBase-root .MuiSvgIcon-root {
                                        fill: #3A3F4D;
                                        width: 18px;
                                    }
                                }
                            }
                        }
                    }

                    .checkbox__main {
                        & label {
                            margin: 0 !important;
                            border: 0;
                            border-top: 1px solid #F3F3F3;
                            border-radius: 0;
                        }
                    }
                }
            }

            .history_activation_filter-footer {
                align-content: center;
                align-items: center;
                display: flex;
                flex-direction: row;
                //height: 50px;
                justify-content: space-between;
                margin-top: 20px;
                padding: 0;

                .MuiButtonBase-root {
                    width: 66px;
                    height: 22px;
                    margin-bottom: 0;
                    span {
                        font-size: .875rem;
                        line-height: 1.25rem;
                    }
                }
            }

            &--open {
                box-shadow: 0 2px 6px rgba(0,0,0,.15);
                display: block;
                max-height: 74vh;
                min-width: 355px;
                overflow-x: auto;
                padding: 0;
                position: absolute;
                right: 0;
                top: 3.5rem;
                z-index: 2;

                /* width */
                &::-webkit-scrollbar {
                    width: 7px;
                    border-radius: 20px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background-color: white;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #B5B7BA;
                    border-radius: 20px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }
            &--no-handle {
                top: 2;
                .filter-menu-item {
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    .accordion__main .MuiAccordion-root .MuiAccordionSummary-root {
                        padding-left: 5px;
                    }
                }
            }
        }

        .multiple-select {
            @apply bg-white;
        }
    }

}

.searchbar__input > div > input {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.show-filters__main {
    @apply mb-6;
    .filters__options {
        @apply mb-2 flex flex-row items-center content-center justify-start;
        gap: 5px;

        .filters-length {
            color: #707070;
            font-family: $tenant_font_family;
        }
        .clean-filter__content {
            @apply pl-2;
            border-left: 1px solid rgba(37, 182, 191, 0.1);
            .clean-filter {
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.03em;
                color: $tenant_color_primario;
                cursor: pointer;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }
    .filters__items {
        .MuiChip-root {
            @apply rounded mr-2;
            //background: rgba(0, 170, 128, 0.1);
            margin-right: 10px;
            background-color: $tenant_color_secundario;
        }
        .MuiChip-label {
            .key-text {
                color: $tenant_blanco;
                font-family: $tenant_font_family;
                font-weight: 600;
            }
            .value-text {
                color: $tenant_blanco;
                font-family: $tenant_font_family;
            }
        }
    }
}
.selected-actions__main {
    @apply w-full h-16 bg-white rounded-t-md;
    border-bottom: 1px solid rgba(#DFDFDF, 0.4);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: nowrap;
    gap: 26px;
    padding-left: 70px;

    .selected__field {
        width: 200px;

        .MuiAutocomplete-inputRoot {
            height: 35px;
            padding: 0 9px;
        }
    }
    .selected-text {
        @apply font-regular text-sm leading-4;
        letter-spacing: 0.05em;
        color: #687182;

        &--number {
            @apply font-semibold;
        }
    }
}

.table__main {
    border: 0 !important;
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1019607843) !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .MuiTable-root {
        .MuiTableHead-root {
            //border-bottom: 0.25rem solid #DFDFDF;
            background-color: #F3F3F3;

            .MuiTableRow-root {
                .MuiTableCell-root {
                    font-family: $tenant_font_family;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 1.375rem;
                    color: #707070;
                    padding: 0rem 1rem;
                    height: 3.75rem;

                    &.MuiTableCell-head {
                        font-weight: 600;
                        color: #707070;
                    }

                    .MuiTableSortLabel-root {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;

                        .MuiBox-root {
                            display: none;
                        }
                    }
                }
            }

        }
        .MuiTableBody-root {
            .MuiTableRow-root {
                .MuiTableCell-root {
                    font-family: $tenant_font_family;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 1.375rem;
                    color: $dark-gray;
                    height: 3.125rem;
                    padding: 0rem 1rem;
                    position: relative;
                    max-width: 200px;
                    .status__main {
                        width: 5rem;
                        height: 1.25rem;
                        background: #E1FCEF;
                        border-radius: 0.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        align-content: center;
                        flex-wrap: nowrap;
                        flex-direction: row;

                        &.status__filled {
                            &--bg {
                                &-green {
                                    background-color: $tenant_color_primario;
                                }
                                &-purple {
                                    background-color: #EDEDFC;
                                }
                                &-yellow {
                                    background-color: $yellow;
                                }
                                &-red {
                                    background-color: #FFEDEF;
                                }
                                &-gray {
                                    background-color: #F6F6F6;
                                }
                                &-darkred {
                                    background-color: red;
                                }
                            }
                        }
                        &.status__outlined {
                            background-color: $tenant_blanco;
                            border-width: 0.0625rem;
                            border-style: solid;
                            &--bg {
                                &-green-dark {
                                    border-color: $tenant_color_primario;
                                }
                                &-green {
                                    border-color: #E1FCEF;
                                }
                                &--purple {
                                    border-color: #807CEA;
                                }
                                &-yellow {
                                    border-color: $yellow;
                                }
                                &-red {
                                    border-color: #E25822;
                                }
                                &-gray {
                                    border-color: #737779;
                                }
                                &-darkred {
                                    border-color: red;
                                }
                            }
                        }
                        .status__text {
                            font-weight: 500;
                            font-size: 0.625rem;
                            line-height: 0.75rem;
                            text-align: center;
                            margin-bottom: 0;
                            &--black {
                                color: #000000;
                            }
                            &--darkgray {
                                color: #c3c3c3;
                            }
                            &--gray {
                                color: #737779;
                            }
                            &--green {
                                color: #14804A;
                            }
                            &--green-dark {
                                color: $tenant_color_primario;
                            }
                            &--purple {
                                color: #807CEA;
                            }
                            &--yellow {
                                color: $yellow;
                            }
                            &--red {
                                color: #E25822;
                            }
                            &--white {
                                color: $tenant_blanco;
                            }
                            &--darkred {
                                color: red;
                            }
                        }
                        &.status__large {
                            width: 100%;
                        }
                    }

                    .switch__main {
                        .MuiFormGroup-root {
                            @apply content-start;
                            .MuiSwitch-root {
                                @apply ml-0;
                            }
                        }
                    }

                    .actions-menu-st {
                        @apply hidden fixed bg-white py-1;

                        z-index: 2;
                        border: 1px solid #dfdfdf;
                        border-radius: 4px;
                        font-size: 13px;

                        &--open {
                            @apply block;
                        }
                        .actions-menu-item {
                            @apply px-3 pb-1;
                            color: $tenant_negro;
                            text-align: left;
                            padding: 6px 10px;
                            &--green {
                                color: $tenant_color_primario;
                            }
                            &--red {
                                color: rgba(211, 0, 0, 1);
                            }
                            &:hover {
                                //background-color: rgba(0, 0, 0, 0.04);
                                background-color: #E2F7ED;
                                cursor: pointer;
                                &--green {
                                    color: $tenant_color_primario;
                                }
                                &--red {
                                    color: rgba(211, 0, 0, 1);
                                }
                            }
                        }
                    }

                    .stores__cell {
                        @apply flex flex-row flex-nowrap items-center justify-start;
                        gap: 5px;

                        .number__text {
                            @apply text-center font-regular text-sm;
                            width: 18px;
                            height: 18px;
                            background-color: $tenant_color_primario;
                            color: $tenant_blanco;
                            border-radius: 50%;
                            text-align: center;
                            line-height: 18px;
                            letter-spacing: 0.05em;
                        }
                    }

                    .table-cell {
                        &--green, .green {
                            color: $tenant_color_primario;
                        }
                    }

                    .hyperlink {
                        color: $hyperlink;
                    }

                    &.empty-table-cell {
                        height: 112px;
                        padding-left: 50px;

                        * {
                            color: #3A3F4D;
                        }
                    }
                }
            }
        }

        .MuiCheckbox-root {
            &.Mui-checked,
            &.MuiCheckbox-indeterminate {
                color: $tenant_color_primario;
            }
        }
    }
    &--gray {
        &.MuiPaper-root {
            background-color: #FBFBFB;
            border: 3px solid rgb(224, 224, 224);
        }
    }
}

.sfooter__main {
    background-color: $tenant_blanco;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 15px;
    margin-top: 0;
    border: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1019607843) !important;

    p {
        color: $dark-gray;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        font-family: $tenant_font_family;
    }

   .left-side > p{
        font-family: $tenant_font_family;
        //color: #707070;
    }

    .right-side {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        font-family: $tenant_font_family;

        & > p {
            //color: #707070;
        }

        .row-per-page-select {
            .MuiSelect-select {
                padding: 5px 20px 5px 15px;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
            }
            svg {
                display: none;
            }
        }
        .MuiButtonBase-root {
            border-radius: 0;
            height: 30px;
            text-align: center;
            width: 30px;
            background-color: $light-gray;

            svg {
                fill: $tenant_negro;
            }
            &:hover {
                background-color: $tenant_color_primario;

                svg {
                    fill: $tenant_blanco;
                }
            }
            &.table-button {
                &--disabled {
                    svg {
                        fill: $dark-gray;
                    }
                }
            }
        }
        .pages__main {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .pages__content {
                cursor: pointer;
                height: 30px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                flex-direction: row;
                flex-wrap: nowrap;

                .pages__number {
                    color: $dark-gray;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                }
                &:hover {
                    background-color: $tenant_color_primario;
                    .pages__number {
                        color: $tenant_blanco;
                    }
                }

                &.pages__content--no-hover{
                    cursor: not-allowed;
                    &:hover {
                        background-color: $tenant_blanco;
                        .pages__number {
                            color: $dark-gray;
                        }
                    }
                }

                &--active {
                    background-color: $tenant_color_primario;
                    .pages__number {
                        color: $tenant_blanco;
                    }
                }
            }
        }
    }

    &--gray {
        background-color: #FBFBFB;
    }
}

.filter__selector__simple__table > div > input {
    height: 19px !important;
}

.additional__buttons__simple__table {
    margin-top: 5px !important;
    margin-bottom: 0 !important;
}

.simple__table__actions-menu-item {
    //min-width: 200px;
    cursor: pointer;
    /* Agregar estilos adicionales para los elementos del menú según tus preferencias */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MuiTableRow-root.MuiTableRow-hover:hover th {
    color: $tenant_color_primario !important;
    background-color: #FAEEED;
}
