@import "../../../assets/styles/variables";

.autocomplete__main {
  &.MuiAutocomplete-root fieldset:hover{
    border-color: $tenant_color_primario;
  }
  .MuiIconButton-label {
    color: $tenant_color_primario;
  }
}
.textfield__label {
  color: $aquamarine;
  font-size: 14px;
  font-weight: $weight-bold;
  line-height: 17px;
  margin-bottom: 2px;
  text-align: start;
}


.textfield__main {
  width: 100%;

  /*
  .MuiOutlinedInput-root {
    padding: 3px;
  }
  */
  .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: $tenant_color_primario;
    }
  }
  input { 
    padding: 12px 9px;
  }
  svg {
    fill: $tenant_color_primario;
  }
}