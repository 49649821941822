@import "../../assets/styles/variables";
.checkbox__container {
  text-align: start;
}

.form-control__main {

  .MuiCheckbox-root {
    color: $tenant_color_primario;
  }

  .MuiFormControlLabel-label {
    color: $gray;
    font-weight: $weight-medium;
    font-size: 14px;
    line-height: 17px;
  }

  .MuiSvgIcon-root {
    color: $tenant_color_primario;
  }
}