@import '../../assets/styles/responsive';
@import "../../assets/styles/variables";

.title{
    font-family: $tenant_font_family;
}
.header__options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $tenant_font_family;

    .searchbar__main_h {
        background: #FEFEFE;
        box-shadow: 0px 1px 4px rgba(54, 62, 71, 0.1);
        border-radius: 30px;
        .searchbar__input {
            min-width: 350px;
            &.MuiPaper-root {
                &.MuiPaper-rounded {
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                }
            }
            .MuiInputBase-root {
                padding-right: 0;
            }
            .searchbar__icon__main_h {
                width: 40px;
                height: 40px;
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                background: $tenant_color_primario;
                border-radius: 30px !important;
                svg {
                    fill: $tenant_blanco;
                }
            }
        }
    }
    .first__options {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 80px;
        justify-content: center;
    }
    .second__options {
        display: flex;
        gap: 1.25rem;
        position: relative;

        .filter-button {
            height: 3rem;
            width: 3rem;

            &.icon-button {
                border-radius: 50%;
            }

            &--gray {
                background-color: #EAEAEA;

                svg {
                    path {
                        fill: #737779;
                    }
                }
            }
        }


        .history-filter-button {
            background-color: white;
            padding: 8px 15px;
            border: 1px solid #B3B3B3;
            border-radius: 30px;
            display: flex;
            align-items: center;
            gap: 6px;
            cursor: pointer;

            & > span {
                font-weight: 400;
                font-size: 16px;
                line-height: 19.36px;
                color: #707070;
            }

            & > svg {
                fill: #707070;
            }

            &:hover {
                background-color: $tenant_color_primario;
                border-color: $tenant_color_primario;
                transition: fill 0.3s;

                & > span {
                    color: $tenant_blanco;
                }

                & > svg {
                    fill: $tenant_blanco;
                }
            }

            &:active {
                opacity: 0.6;
            }
        }

        .multiple-select {
            background-color: white !important;
        }
    }
}

.MuiPaper-root {
    &.MuiPaper-elevation1 {
        box-shadow: inherit;
    }
}

.status__selector__custom-selector {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 5px 9px;
    cursor: pointer;
    font-size: 12px;
}
.label-contain{
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row !important;
    flex-wrap: nowrap;
    height: 50px;
    margin-left: 16px !important;
    margin-right: 0px !important;
}

.history_activation_filter-footer {
    gap: 20px
}

.history_activation_filter_button {
    //font-size: 14px;
    //height: 31px;
    flex: 1;
}

.history_activation_filter_button.history_activation_filter_button__variant {
    background-color: white;
    color: #707070;
    border: 1px solid #dcd9d9;
}

.history_activation_filter_button.history_activation_filter_button__variant:hover {
    background-color: $tenant_color_primario;
    color: $tenant_blanco;
    border: 1px solid $tenant_color_primario;
    opacity: 0.85;
}

.assigned__main {
    margin-top: 14px;
    margin-bottom: 20px;
}

.activation_history_request_number:hover {
    text-decoration: underline;
    color: $tenant_color_primario;
    cursor: pointer;
}

.activation_history_status{
    border: 1px solid #B3B3B3;
    padding: 2px 8px;
    border-radius: 16px;
    background-color: #B3B3B3;
    color: white;
    font-family: $tenant_font_family;

    &.activation_history_status__SEND {
        border-color: #E5E1FF;
        background-color: #E5E1FF;
        color: #46389F;
    }

    &.activation_history_status__APPROVED {
        border-color: #D7FADD;
        background-color: #D7FADD;
        color: #148C28;
    }

    &.activation_history_status__CANCELLED {
        border-color: #FFD0D0;
        background-color: #FFD0D0;
        color: #C21000;
    }
}
