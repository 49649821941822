@import '../../assets/styles/responsive';
@import "../../assets/styles/variables";

.table-activations{
 min-height: calc(55vh - 80px);
 overflow-y: scroll;
 max-height: calc(55vh - 80px) !important;
}
.button-finalizar{
padding-left: 15px !important ;
font-weight: 600;
}
.form-activaction{
    padding-top: 70px;
}
.title{
    font-weight: 700;
    font-size: 25px;
    line-height: 36.31px;
    font: Inter;
    color: #707070;
}
.description{
    margin-top: 5px;
    font-weight: 300;
    font-size: 15px;
    line-height: 21.78px;
    font: Inter;
    color: #707070;
}
.select-form__activation{
    margin: 0px !important;
}

.form__request__history > .MuiContainer-root{
  width: 500px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.form__request__history{
    margin-left: -20px;
    margin-top: 10px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.InputDate {
    position: relative;
    .react-datepicker-wrapper{
        width: 50% !important;
    }
    input {
        font-size: 14px;
        color: black;
    }
    img {
        position: absolute;
        right: 20px;
    }
}
.head_main{
    float: right;
    display: inline;
}
.head_table{
    float:left;
    width: 170px;
}
.footer_main{
    float: right;
    display: inline;
}
.footer_table{
    float:left;
    width: 125px;
}
.vertical__delete__button {
    float: right;
    margin-bottom: 0 !important;
    background: linear-gradient(180deg, #FFFF 0%, #FFFF 100%) !important;
    border-color: #B3B3B3 !important;
    margin-bottom: 20px !important;
    span{
        color: #707070 !important;
    }
}
.contenedorBody{
    z-index:40;
    opacity:.5;
}
.EndSide{

    @include desktop{
      display: flex;
      float:left;
    }
    @include tablet-large {
      display: flex;
      float:left;
    }
}


.request_activation_drawer_animation {
  transition: width 0.5s;
}

.lateralBar{
    transform: scale(1);
    opacity: 1;
    height: 100vh;
}
.general_model_top_content__turn_detail_modal{
    padding: 25px 28px 22px 18px;
    display: flex;
    float:left;
    position: fixed;
    width: 100%;
    height: 10px;
}
.general_container_end{
  padding: 80px 65px 22px 45px;

    display: flex;
    width: 100%;;
}
.div_containe{
    .money{
        float: right;
        padding-left: 150px;
    }
}

.request_activation_adding_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0;
}

.request_activation_adding_text {
  margin-top: 30px;
  cursor: pointer;
}

.request_activation_adding_text:hover {
  opacity: 0.6;
  text-decoration: underline;
}

.request_activation_adding_text:active {
  opacity: 1;
}

.request_activation_container_terms_checkbox > .checkbox__container{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.request_activation_container_terms_checkbox {
  margin-top: 30px;
}

#activation_terms_conditions {
  margin-right: 0 !important;
  text-align: justify;
}

.activaton_drawer_content__button {
  margin-top: 30px;
  width: 100%;
  padding-left: 0;
}


.activation_drawer_td_money {
  text-align: right;
}

.terms_conditions__text {
  font-family: "Inter", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: #707070;
}
.input-select-date{
    border:1px solid rgba(179, 179, 179, 1);
    border-radius: 30px;
    padding: 8px 15px 8px 15px;
    &.highlighted-week {
        .day-wrapper {
          &.selected,
          &:hover {
            background-color: red;
            color: #000; // Change the text color if needed
          }
        }
    }
}
.rmdp-header-values {
    color: $tenant_color_primario !important;
    font-weight: 600;
  }

  .rmdp-arrow-container > i{
    border-color: $tenant_color_primario !important;
  }

  .rmdp-arrow-container:hover {
    background-color: $tenant_color_primario !important;
  }

  .rmdp-arrow-container:hover > i {
    border-color: white !important;
  }

  .rmdp-week-day {
    color: $tenant_color_primario !important;
  }
.rmdp-day.rmdp-selected span{
    background-color: $tenant_color_primario !important;
    color: #fff !important;
}
.selectActiveToday {
    background-color: $tenant_color_primario !important;
    color: #fff !important;
}
.rmdp-day span:hover{
    background-color: #FAEEED !important;
    color: $tenant_negro !important;
}

.multiSelect{
    background-color: #FAEEED !important;
    color: $tenant_negro !important;
}
.desSelect{
    background-color: white !important;
    color: $tenant_negro !important;
}
.table_turns
{
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid rgb(113, 113, 113);
    border-collapse: initial !important;
}
.table_turns th{
    background-color: rgba(250, 250, 251, 1);
    font-family: "Inter", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    text-transform: uppercase;
    border-right: 1px solid rgba(212, 212, 212, 1);
    text-align: center;
    width: 125px !important;
    min-width: 50px !important;
    max-width: 125px !important;
    &:first-child {
        // Establecer un tamaño diferente para la primera columna
        width: 145px !important;
        min-width: 145px !important;
        max-width: 145px !important;
    }
}
.table_turns td {
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
    color: #707070;
}
.table_turns th span {
    margin-left: 3px;
    font-family: "Inter", "Helvetica", "Arial", sans-serif;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 23px !important;
    color: rgba(0, 0, 0, 1);
}
.table_body_tr td {
    border-right: 0.5px solid rgba(212, 212, 212, 1) !important;
    border-top: 1.5px solid rgba(212, 212, 212, 1);
}
.table_body_tr td:nth-child(1) {
    border-left:4px solid $tenant_color_primario !important;

}
.table_turns thead tr>th{
    padding: 8px 8px;
}
.horario_turn{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 3px;
    background-color: $tenant_color_primario;
    padding: 10px;
    color: #FFFFFF;
    min-width: 80px !important;
    max-width: 125px !important;
    font-weight: 400;
    font-family: "Inter", "Helvetica", "Arial", sans-serif;
    font-size: 12px;
}
.horario_turn-vacio{
    min-width: 80px !important;
    max-width: 125px !important;
}
.horario_turn:hover{
    background-color: #FF0000;
    cursor: pointer;
}
.icon_calendar{
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: -6px !important;
}
.horario{
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.store_label{
    font-family: "Inter", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px !important;
    color: $tenant_blanco;
    align-items: center !important;
}
.activation_table_empty_rows_td {
    padding: 20px 20px !important;
    text-align: center !important;
}
.detail_one{
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: "Inter", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px !important;
    color: #707070;
}
.MuiBox-root{
    padding: 0px !important;
    margin-top:10px !important;
}
.Mui-selected{
    color: #ffffff !important;
    font-family: "Inter", "Helvetica", "Arial", sans-serif;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px !important;
}
.MuiTabs-indicator{
    background-color: #46389F !important;
}
.title_tab{
    font-family: "Inter", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px !important;
    color: $tenant_blanco;
}
.hourTurn{
    display: flex;
}
.search-input-container {
    position: relative;
    display: inline-block;
}
.search-input {
    padding: 8px 12px 8px 12px;  // Ajusta el espaciado según tus preferencias
    border: 1px solid #ccc;
    border-radius: 20px;  // Borde redondeado
    width: 130px !important;
    min-width: 130px !important;
    max-width: 130px !important;  // Ajusta el ancho según tus necesidades
    font-size: 14px;  // Ajusta el tamaño de fuente según tus preferencias
    background-image: url('../../assets/images/fi-rr-search.svg');  // Ruta de la imagen de la lupa
    background-repeat: no-repeat;
    background-position:10px 50%;  // Ajusta la posición del icono dentro del input
    &.empty {
        background-image: none !important;  // Oculta el icono cuando el input está vacío
      }
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 20px;  // Ajusta el ancho según tus necesidades
        height: 20px;  // Ajusta la altura según tus necesidades
        background-color: #fff;  // Ajusta el color de fondo según tus necesidades
        right: 10px;  // Ajusta la posición del pseudo-elemento según tus necesidades
        background-image:none;
        right: 40px;
    }
}
.search-input::placeholder {
    padding-left: 15px;  // Ajusta el padding para el placeholder
    font-family: "Inter", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 14 !important;
  }
