@import "../../../assets/styles/variables";

.request_activation_main_table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 20px;
  overflow: hidden;
  //border: 2px solid #F3F3F3;
  box-shadow: 0 4px 8px -2px #1018281A;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 320px;
}

.request_activation_main_table th {
  background-color: #F3F3F3;
  font-family: $tenant_font_family;
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
  color: #707070;
  text-align: left;
  text-transform: uppercase;
}

.request_activation_main_table td {
  padding: 8px;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: #707070;
}

.request_activation_main_table th span {
  display: table-cell;
  padding: 12px 8px;
}

.request_activation_main_table_checkbox_container {
  text-align: center !important;
}

th.request_activation_main_table_checkbox_container {
  padding-left: 25px;
}

td.request_activation_main_table_request_number_td {
  padding: 20px 8px !important;
  font-weight: 600;
  text-align: left;
  color: #707070;
  margin-left: 25px;
}

.request_activation_main_table_brand_logo {
  height: 66px;
}

.request_activation_main_table_brand_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.request_activation_main_table_brand_container span {
  font-weight: 600;
}

.request_activation_main_table_store_name {
  font-weight: 600 !important;
}

.request_activation_main_table_subtotal {
  font-weight: 600 !important;
}

tbody tr.request_activation_last_row_separation {
  border-bottom: 2px solid #F3F3F3;
}

tbody tr.request_activation_last_row_separation.request_activation_last_table_row {
  border-bottom: 0;
}

//TODO: revisar
tbody tr.request_activation_last_row_separation.request_activation_last_table_row td.request_activation_main_table_brand_container{
  border-bottom: 0;
  padding-bottom: 32px;
}

tbody tr.request_activation_last_row_separation td.request_activation_main_table_brand_container{
  border-bottom: 2px solid #F3F3F3;
  padding-bottom: 30px;
}

.request_activation_number_input_flex {
  justify-content: flex-start !important;
}

.rmdp-container > :nth-child(2) {
  position: fixed !important;
}

.rmdp-container > :nth-child(3) {
  position: fixed !important;
}

.request_activation_trash_icon {
  margin-top: 7px;
  cursor: pointer;
}

.request_activation_trash_icon:hover {
  opacity: 0.6;
}

.request_activation_trash_icon:active {
  opacity: 1;
}

.activation_table_empty_rows_td {
  padding: 20px 20px !important;
  text-align: center !important;
}

.rmdp-header-values {
  color: $tenant_color_primario !important;
  font-weight: 600;
}

.rmdp-arrow-container > i{
  border-color: $tenant_color_primario !important;
}

.rmdp-arrow-container:hover {
  background-color: $tenant_color_primario !important;
}

.rmdp-arrow-container:hover > i {
  border-color: white !important;
}

.rmdp-week-day {
  color: $tenant_color_primario !important;
}
