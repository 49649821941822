@import "../../assets/styles/variables";

.activation_detail_back_container {
  font-weight: 400;
  font-family: $tenant_font_family;
  font-size: 15px;
  line-height: 20px;
  color: #707070;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.activation_detail_back_container:hover {
  text-decoration: underline;
  color: $tenant_color_primario;

}

.activation_detail_back_container:active {
  color: #707070;
}

.activation_detail_back_container svg {
  fill: #707070;
}

.activation_detail_back_container:hover svg {
  fill: $tenant_color_primario;
}

.activation_detail_general_detail_table {
  border-collapse: collapse;
  width: 100%;
}

table.activation_detail_general_detail_table  th {
  padding: 10px 0;
  text-align: left;
  color: #171212;
  font-family: $tenant_font_family;
  font-size: 20px;
  font-weight: 700;
  line-height: 31.2px;
  vertical-align: top;
}

.activation_detail_general_detail_table  td {
  padding: 10px 0;
  vertical-align: top;
}

.activation_detail_general_detail_table  td > span {
  font-family: $tenant_font_family;
  color: #707070;
  font-weight: 300;
  line-height: 24.96px;
}

.activation_detail_general_detail_table  td:first-child {
  width: 350px;
}

.activation_detail_general_detail_subtitle {
  font-weight: 550 !important;
}

.activation_detail_general_detail_bold {
  font-weight: 550 !important;
}

.activation_detail_general_detail_total {
  color: $tenant_color_primario !important;
  font-size: 20px !important;
}

.activation_detail_general_detail_subtotal {
  font-size: 20px !important;
}

.activation_detail_general_detail_dates {
  cursor: pointer;
}

.activation_detail_general_detail_dates:hover {
  color: $tenant_color_primario;
}

.activation_detail_general_detail_text_date {
  color: #707070;
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 300;
  font-family: $tenant_font_family;
}

.activation_detail_general_detail_text_title {
  color: #171212;
  font-size: 30px;
  line-height: 36.31px;
  font-weight: 700;
  font-family: $tenant_font_family;
  margin: 0;
  margin-top: 10px;
}

.activation_detail_general_detail_text_brand {
  color: $tenant_color_primario;
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 550;
  font-family: $tenant_font_family;
  margin: 5px 0;
  text-transform: uppercase;
}

.activation_detail_general_detail_text_title span {
  color: $tenant_color_primario;
}

.activation_detail_general_detail_text_activation_type {
  color: #707070;
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 300;
  font-family: $tenant_font_family;
  margin: 0;
  margin-bottom: 20px;
}

.activation_detail_general_detail_text_activation_type > span {
  font-weight: 550;
}

.activation_detail_workers_ul {
  padding: 0 0;
  list-style-type: none;
  margin: 0;
  margin-top: 10px;
}

.activation_detail_workers_ul > li{
  color: #707070;
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
  cursor: pointer;
}

.activation_detail_workers_ul > li > svg{
  fill: #707070;
  color: red;
}

.activation_detail_general_workers_subtitle {
  color: #37352F;
  font-weight: 700;
  font-size: 16px;
  line-height: 22.4px;
  font-family: $tenant_font_family;
  margin: 0;
}
