@import "../../assets/styles/variables";

.alter_tenant__btn {
  background-color: $tenant_blanco;
  color: $tenant_color_primario;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  padding: 8px 22px;
  min-width: 64px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  letter-spacing: 0.02857em;
  font-family: $tenant_font_family;
  font-weight: 600;
  line-height: 24px;
  height: 44px;
  font-size: 16px;
  border: 1px solid $tenant_color_primario;
}

.alter_tenant__btn_disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: $tenant_gris_1;
  color: $tenant_blanco;
  font-weight: 400;
  opacity: 0.5;
  border: 1px solid $tenant_gris_1;
}

.alter_tenant__btn_w100 {
  width: 100%;
}

.alter_tenant__btn:hover {
  opacity: 0.85;
  box-shadow: none;
}

.alter_tenant__btn:active {
  opacity: 1;
}

.alter_tenant__btn > svg {
  fill: $tenant_color_primario;
  margin-right: 15px;
}

.alter_tenant__btn_disabled > svg {
  fill: $tenant_gris_1;
}
