@import "../../../assets/styles/variables";

.error_message__main {
    text-align: start;
}

.error_message {
    color: $tenant_color_primario;
    font-weight: $weight-bold;
    font-size: 14px;
    line-height: 17px;

    &--white {
        color: $tenant_blanco;
        text-align: start;
    }
}