@import "../../assets/styles/variables";

.tenant__input_container{
  display: flex;
  flex-direction: column;
}

.tenant__input_container_w100 {
  width: 100%;
}

.tenant__text_input {
  border: 1px solid $tenant_gris_1;
  border-radius: 20px;
  height: 38px;
  padding: 8px 16px;
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
  color: $tenant_gris_2;
  background-color: $tenant_blanco;
}

.tenant__text_input::placeholder {
  color: #B3B3B3;
}

.tenant__text_input:disabled {
  opacity: 0.5;
}

.tenant__text_input.tenant__input__border__error__color {
  border-color: $tenant_rojo;
}
.tenant__text_input.tenant_input_complete{
  border: 1px solid $tenant_color_secundario !important;
}

@media only screen and (max-width: $md) {
  input{
    min-height: 44px;
    max-height: 44px;
    max-width: 100%;
    min-width: 100%;
  } 
  .tenant__text_input{
    padding:8px 10px;
  }
}