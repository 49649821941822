@import "../../assets/styles/variables";

.sign_in_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4%;
    flex-direction: column;
}

.sign_in_image {
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
}

.sign_in_form_container {
    width: 777px;
    height: 420px;
    background: $tenant_gris_3;
    border-radius: 50px;
}


.group-inputs {
    display: flex;
    flex-direction: row;
}

.sign_in_title {
    font-weight: 600;
    line-height: 24px;
    font-size: 24px;
    color: $tenant_gris_1;
    text-align: center;
    flex-direction: row;
    word-wrap: break-word;
    margin-bottom: 30px;
}

.sign_in_subtitle {
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    color: $tenant_gris_1;
    text-align: center;
    margin-bottom: 30px;
}

.tenant__input_container input {
    /* Estilos para los inputs */
    margin-right: 8px;
    /* Espacio entre los inputs */
}

.sign_in_footer_cross {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 60px;
    gap: 20px;
    font-family: 'Nunito Sans', 'Helvetica Neue', 'Inter', Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 24px;
    font-size: 14px;
    color: $tenant_gris_1 !important;
}

.sign_in_footer__sub_cross {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.forgotten {
    color: $tenant_gris_1 !important;
}

.sign {
    color: $tenant_color_secundario !important;
}

@media only screen and (max-width: $md) {
    .group-inputs {
        display: block;
        flex-direction: row;
    }
    .sign_in_form_container {
        width: 250px;
        min-width: 250px;
        max-width: 250px;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .sign_in_image {
        display: none;
    }
    .tenant__input_container input {
        /* Estilos para los inputs */
        margin-right: 0px;
        /* Espacio entre los inputs */
    }

}