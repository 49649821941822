@import "../../assets/styles/variables";

.tenant__btn {
  //background: $tenant_main_background;
  background-color: $tenant_color_primario;
  color: $tenant_blanco;
  cursor: pointer;
  border-radius: 20px;
  padding: 0px 0px !important;
  min-width: 64px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  letter-spacing: 0.02857em;
  font-weight: 400;
  line-height: 24px;
  height: 44px;
  font-size: 16px;
}

.tenant__btn_disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: $tenant_gris_1;
  color: $tenant_blanco;
  font-weight: 400;
  opacity: 0.5;
}

.tenant__btn_w100 {
  width: 100%;
}

.tenant__btn:hover {
  opacity: 0.85;
}

.tenant__btn:active {
  opacity: 1;
}


.tenant__btn > svg {
  fill: $tenant_blanco;
  margin-right: 15px;
}

.tenant__btn_disabled > svg {
  fill: $tenant_blanco;
}
