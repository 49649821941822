@import './helpers/fonts.scss';
@import './variables';
@import './helpers/fonts';
@import './helpers/normalize';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

body {
    margin: 0;
}

*,
.MuiTypography-root,
.MuiListItemText-primary,
.MuiTypography-body1 {
    font-family: $tenant_font_family;
}

#react-simple-modal-container {
  .modal__main {
    min-height: auto !important;

    .rsm-flex-1 {
      padding: 0;
    }
  }
}

.skeleton {
  span {
    margin-bottom: 10px;
  }
}

.skeleton-center {
  span {
    margin: auto;
    margin-bottom: 10px;
  }
}

.flex-container {
  display: flex;
  align-content: space-around;
  flex-wrap: wrap;
  justify-content: center;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.hidden {
  visibility: hidden;
}

.MuiSvgIcon__disabled{
  fill: gray;
  cursor: not-allowed;
}

.contact__signing__skeleton__item{
  align-content: space-between;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 10px;
}

.fw-regular{
  font-weight: 400 !important;
}

.fw-semi-bold{
  font-weight: 600 !important;
}

.tx-small{
  font-size: 14px !important;
}

.tx-medium{
  font-size: 16px !important;
}
