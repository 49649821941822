@import "../../assets/styles/variables";

.tenant__input_container{
  display: flex;
  flex-direction: column;
}

.tenant__input_container_w100_password {
  width: 100%;
}

.tenant__text_input_password {
  border: 1px solid $tenant_gris_1;
  border-radius: 20px;
  height: 38px;
  padding: 8px 16px;
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
  color: $tenant_gris_2;
  background-color: $tenant_blanco;
  padding-right: 50px;
  
}

.tenant__text_input_password::placeholder {
  color: #B3B3B3;
}

.tenant__text_input_password:disabled {
  opacity: 0.5;
}

.tenant__text_input_password.tenant__input__border__error__color_password {
  border-color: $tenant_rojo;
}

.tenant__text_input_password_container {
  position: relative;
}

.tenant__password_toggle_button {
  position: absolute;
  right: 10px; // Ajusta este valor según sea necesario
  top: 52%;
  transform: translateY(-50%);
  background-color: $tenant_blanco;
  color: $tenant_gris_2;
  cursor: pointer;
  opacity: 0.6;
}

.tenant__password_toggle_button:hover {
  opacity: 1;
}
.tenant__text_input_password.tenant_input_complete{
  border: 1px solid $tenant_color_secundario !important;
}

.tenant__text_input_password.tenant__input__border__error__color_pasword{
  border-color: $tenant_rojo;
}

@media only screen and (max-width: $md) {
  input{
    min-height: 44px;
    max-height: 44px;
    max-width: 100%;
    min-width: 100%;
  } 
  .tenant__text_input_password{
    padding-right: 33px;
  }
}