@import "../../assets/styles/variables";

.MuiBox-root {
    //.MuiBox-root {
    //    background-color: $white;
    //    border-top-right-radius: 10px;
    //    border-top-left-radius: 10px;
    //    border: 0;
    //    box-shadow: 0px 4px 18px rgba(117, 131, 142, 0.25);
    //    padding: 8px 6px;
    //    position: relative;
    //    z-index: 2;
    //}

    //.tab-panel {
    //    position: relative;
    //    z-index: 1;
    //    .MuiBox-root {
    //        background-color: $white;
    //        border-radius: 0;;
    //        border: 0;
    //        border-bottom-left-radius: 10px;
    //        border-bottom-right-radius: 10px;
    //        box-shadow: 0px 0px 0 rgba(117, 131, 142, 0.25);
    //        padding: 32px;
    //    }
    //}
}

.MuiTabs-flexContainer {
    //align-items: center;
    //justify-content: space-around;
    //align-content: center;
    //flex-wrap: nowrap;
    //flex-direction: row;

    .MuiButtonBase-root {
        color: #707070;
        font-family: $tenant_font_family;
        font-weight: 400;
        font-size: 18px;
        line-height: 21.78px;
        text-transform: none;

        &.Mui-selected {
            color: $tenant_color_primario !important;
            font-family: $tenant_font_family !important;
            font-weight: 400 !important;
            font-size: 18px !important;
            line-height: 21.78px !important;
        }
    }
}

.MuiTabs-indicator {
    background-color: $tenant_color_primario !important;
}

