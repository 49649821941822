@import 'assets/styles/_base.scss';

// TODO: temporal
.app__content {
  padding-left: 0;
  padding-right: 0;
}

.error_message {
  &--red {
    color: $tenant_rojo !important;
    text-align: start;
  }
}


.global {

  &__input__text {
    color: red;
  }

  &__btnWhite {
    width: 80% !important;
    margin: auto !important;
    color: #2FAD6F !important;
    background-color: #E2F7ED !important;
    margin-bottom: 20px !important;
    @mixin desktop {
      width: 30% !important;
      margin-left: 35% !important;
    }

    &:hover {
      color: #2FAD6F !important;
      background-color: #E2F7ED !important;
      box-shadow: 0 4px 10px rgba(20, 43, 127, 0.25);
    }
  }

  &__btnGreen {
    margin: auto !important;
    width: 80% !important;
    @mixin desktop {
      width: 30% !important;
      margin-left: 35% !important;
    }

    &:hover {
      background: #2FAD6F !important;
      color: #E2F7ED !important;
      box-shadow: 0 4px 10px rgba(20, 43, 127, 0.25);
    }
  }

  &__btnLink {
    margin: auto !important;
    width: 80% !important;
    color: #2FAD6F !important;
    text-decoration-line: underline !important;
    background-color: white !important;
    margin-bottom: 20px !important;
    @mixin desktop {
      width: 30% !important;
      margin-left: 35% !important;
    }
  }

  &__title {
    font-family: $tenant_font_family;
    font-style: normal;
    color: #FFFFFF;
  }

  &__href{
    font-family: $tenant_font_family;
    font-style: normal;
    line-height: 26px;
  }

}

.only-mobile {
  cursor: pointer;
  @mixin desktop {
    display: none;
  }
  @mixin tablet-large {
    display: none;
  }
}

/* Tenant css */
.tenant__input_container{
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.tenant__error_message {
  margin-top: 5px;
  font-family: $tenant_font_family;
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
  color: $tenant_rojo;
}

.tenant__input__border__error__color {
  border-color: $tenant_rojo;
}

.tenant__input_label {
  font-family: $tenant_font_family;
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
  color: #707070;
  margin-bottom: 5px;
}


.app__main {
  padding-top: 60px;
  //width: 100%;
  padding-left: 30px;
  padding-right: 125px;
}

.app__content {
  min-width: 600px;
  padding: 0px 15px 50px 15px;
  display: flex;
  flex-direction: column;

  @media (max-width:  768px) {
    min-width: 100%;
  }

  form {
    width: 100%;
  }
}

.title{
  font-weight: 700;
  font-size: 25px;
  line-height: 36.31px;
  font-family: $tenant_font_family;
  color: #707070;
}

.description{
  margin-top: 5px;
  font-weight: 300;
  font-size: 15px;
  line-height: 21.78px;
  font-family: $tenant_font_family;
  color: #707070;
}

.Mui-checked {
  color: $tenant_color_primario !important;
}

.MuiFormControlLabel-root:hover {
  text-decoration: underline;
}

.checkbox__main .MuiCheckbox-root .MuiSvgIcon-root{
  color: $tenant_color_primario;
}
