@import "../../../assets/styles/variables";

.date-picker__main {
    .MuiFormControl-root {
        @apply w-full;

        .MuiInputBase-root {
            fieldset {
                border: 1px solid rgba(37, 182, 191, 0.1);
                box-shadow: 0px 1px 4px rgba(59, 69, 79, 0.1);
                border-radius: 8px;
            }
            &.Mui-focused, &:hover {
                fieldset {
                    border-color: $tenant_color_primario;
                }
            }
        }
    }
}
.text-field {
    &.MuiTextField-root {
        width: 100%;
    }
    .MuiOutlinedInput-root {
        background-color: #FBFBFB;
        border: 1px solid rgba(37, 182, 191, 0.1);
        box-shadow: 0px 1px 4px rgba(52, 60, 68, 0.1);
        border-radius: 8px;
        &:hover {
            .MuiOutlinedInput-notchedOutline {
                border-color: $tenant_color_primario;
            }
        }
        &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                border-color: $tenant_color_primario;
            }
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
        }
        .MuiOutlinedInput-input {
            padding-top: 21px;
            padding-bottom: 21px;
        }
    }
    .MuiInputAdornment-root {
        .MuiButtonBase-root {
            .MuiSvgIcon-root {
                fill: $tenant_color_primario;
            }
        }
    }
}

.MuiButtonBase-root {
    &.MuiPickersDay-root {
        &:focus {
            &.Mui-selected {
                background-color: $tenant_color_primario;
                color: $tenant_blanco;
            }
        }
        &.Mui-selected {
            background-color: $tenant_color_primario;
        }
    }
}

.PrivatePickersYear-root {
    .PrivatePickersYear-yearButton {
        &.Mui-selected {
            background-color: $tenant_color_primario;
            &:hover,
            &:focus {
                background-color: $tenant_color_primario;
            }
        }
    }
}
